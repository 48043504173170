let chartColors = {
  COLOR1: '#3F96A8',
  COLOR2: '#009ca6',
  COLOR3: '#687fd2',
  COLOR4: '#ffb66d',
  COLOR5: '#B8DAF8',
  COLOR6: '#F6DB82',
  COLOR7: '#239BB8',
  COLOR8: '#FFB2A2',
  COLOR9: '#3F96A8',
  COLOR10: '#009ca6',

}

export default chartColors
