import React from 'react'
import FintupView from '../FintupView'
import { FormattedMessage } from 'react-intl'
import SingleFormContainer from '../common/SingleFormContainer'
import './Home.scss'
import FintupButton from '../common/forms/FintupButton'
import constants from '../../utils/constants'
import SocialLogin from '../common/SocialLogin'
import InputTextLabel from '../common/forms/InputTextLabel'
import RSVP from 'rsvp'
import navUtils from '../../core/navUtils'
import { doLoginPost } from '../../utils/fintup-api'
import urls from '../../utils/urls'
import utils from '../../utils/utils'
import loginHelper from './loginHelper'
import SessionController from '../../core/SessionMobx'
import { getBooleanConfig } from '../../config/config'
import MetaTags, { ReactTitle } from 'react-meta-tags';
import HeaderTags from '../common/HeaderTags'
import config from '../../config/config'

class Home extends FintupView {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: ''
    }
  }

  componentDidMount () {
    if (SessionController.isSessionExpired()) {
      let sessionExpiredData = SessionController.getSessionExpiredMessage()
      let sessionExpiredKey = sessionExpiredData.code
      this.showAlert({textKey: `Login.validation.${sessionExpiredKey}`})
    }

    SessionController.exitSession()
  }

  showAlert (data) {
    this.setStatePromise({ alertText: <FormattedMessage id={data.textKey} /> })
  }

  errorLogin (result) {
    this.setStatePromise({ alertText: result.message })
  }

  responseErrorLogin (response) {
    this.errorLogin(response)
    return RSVP.reject()
  }

  doReactivate () {
    navUtils.goToInternal(urls.internal.reactivate)
  }

  doLogin (ev) {
    if (this.state.username === '' || this.state.password === '') {
      this.setStatePromise({ alertText: <FormattedMessage id="Login.formValidate" /> })
      return
    }
    this.setStatePromise({
      alertText: ''
    })
    doLoginPost({
      username: this.state.username,
      password: this.state.password
    })
      .then(loginHelper.responseSuccessLogin.bind(this))
      .catch(this.responseErrorLogin.bind(this))
  }

  render () {
    let sepTextKey = 'home.login.or'
    let legendKey = 'home.login.legend'
    let renderSocialComponent = getBooleanConfig('socialLogin', true)
    if (!renderSocialComponent) {
      sepTextKey = 'home.login.legend'
      legendKey = undefined
    }
    return (
      <div className="newStyles row home fintupResponsiveForm">
        <HeaderTags title="headerTags.title"></HeaderTags>
        <div className="loginContainer col-sm-6 col-md-push-6">
          <SingleFormContainer alertText={this.state.alertText} className="double" titleKey="home.login.title" legendKey={legendKey}>
            
            <div className="clearAll marginTop15"/>
            <div className="separator"><hr/></div>
            <div className="separatorCenter"><FormattedMessage id={sepTextKey} /></div>
            <div className="separator"><hr/></div>
            <InputTextLabel placeholder="home.login.email" name="username" callBackOnChange={this.commonHandleChange.bind(this)} />
            <InputTextLabel type="password" placeholder="home.login.password" name="password" callBackOnChange={this.commonHandleChange.bind(this)}/>
            <div className="row">
              <div className="col-sm-6"></div>
              <div className="loginButtonContainer col-sm-6 col-xs-12">
                <FintupButton type="submit" size={constants.BUTTON_SIZE.FULL} buttonClassName="btnSelector login-color" textKey="home.login.button" action={this.doLogin.bind(this)} />
                <div className="forgetContainer" onClick={this.doReactivate.bind(this)} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="home.login.forget" /></div>
                <div className="registerTextContainer" onClick={() => navUtils.goToInternal(getBooleanConfig('enableRegister') ? urls.internal.register() : urls.internal.invest)} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="home.login.register" /></div>
              </div>
            </div>
            <div className="clearAll"/>
          </SingleFormContainer>
        </div>
        <div className="registerContainer col-sm-6 col-md-pull-6">
          <SingleFormContainer alertText="" className="double" titleKey="home.register.title" legendKey="home.register.legend">
            <p className="registerDot"><img src="/img/checked-bluefintup.svg" alt="tick"/><FormattedMessage id="home.register.dot1"/></p>
            <p className="registerDot"><img src="/img/checked-bluefintup.svg" alt="tick"/><FormattedMessage id="home.register.dot2"/></p>
            <p className="registerDot"><img src="/img/checked-bluefintup.svg" alt="tick"/><FormattedMessage id="home.register.dot3"/></p>
            <p className="registerDot"><img src="/img/checked-bluefintup.svg" alt="tick"/><FormattedMessage id="home.register.dot4"/></p>
            <p className="registerDot"><img src="/img/checked-bluefintup.svg" alt="tick"/><FormattedMessage id="home.register.dot5"/></p>
            <FintupButton buttonClassName="btnSelector"className="marginTop20 marginBottom20" size={constants.BUTTON_SIZE.FULL} textKey="home.register.button" action={() => navUtils.goToInternal(getBooleanConfig('enableRegister') ? urls.internal.register() : urls.internal.invest)}/>
            <div className="clearAll"/>
          </SingleFormContainer>
        </div>
      </div>
    )
  }
}

export default Home
