import React from 'react'
import FintupView from '../FintupView'
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl'
import SingleFormContainer from '../common/SingleFormContainer'
import './HomeNew.scss'
import FintupButton from '../common/forms/FintupButton'
import constants from '../../utils/constants'
import SocialLogin from '../common/SocialLogin'
import InputTextLabel from '../common/forms/InputTextLabel'
import RSVP from 'rsvp'
import navUtils from '../../core/navUtils'
import { doLoginPost, reactivateEmailService } from '../../utils/fintup-api'
import urls from '../../utils/urls'
import utils from '../../utils/utils'
import loginHelper from './loginHelper'
import SessionController from '../../core/SessionMobx'
import { getBooleanConfig } from '../../config/config'
import MetaTags, { ReactTitle } from 'react-meta-tags';
import HeaderTags from '../common/HeaderTags'
import config from '../../config/config'
import Modal from '../common/modal/Modal'
import ModalBody from '../common/modal/ModalBody'
import ModalHeader from '../common/modal/ModalHeader'
import FintupForm from '../common/forms/FintupForm'
import Reactivate from '../reactivate/Reactivate'

class HomeNew extends FintupView {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      usernameFieldErrorText: '',
      passwordFieldErrorText: '',
      showModal: false
    }
  }

  componentDidMount () {
    if (SessionController.isSessionExpired()) {
      let sessionExpiredData = SessionController.getSessionExpiredMessage()
      let sessionExpiredKey = sessionExpiredData.code
      this.handleNotification({titleKey: 'Login.validation.title.expired', textKey: `Login.validation.${sessionExpiredKey}`, type: constants.NOTIFICATION_TYPE.ERROR})
    }

    SessionController.exitSession()
  }

  hideModals(){
    this.setStatePromise({
      showModal: false})
  }

  modalGetHeader (value) {
    return (
      <ModalHeader clickClose={this.hideModals.bind(this)}>
        <FormattedMessage id={value} />
      </ModalHeader>
    )
  }

  showModalEv(ev, value){
    ev.preventDefault()
    var obj = {}
    obj[value] = true
    this.setStatePromise(obj)
  }

  showAlert (data) {
    this.setStatePromise({ alertText: <FormattedMessage id={data.textKey} /> })
  }

  errorLogin (result) {
    this.handleNotification({titleKey: 'activationView.legendKo', textKey: result.message ? result.message : result.textKey, type: constants.NOTIFICATION_TYPE.ERROR})
  }

  responseErrorLogin (response) {
    this.errorLogin(response)
    return RSVP.reject()
  }

  doReactivate () {
    navUtils.goToInternal(urls.internal.reactivate)
  }

  doLogin (ev) {
    let isError = false
    this.setStatePromise({usernameFieldErrorText: ''})
    if (this.state.username === '') {
      this.setStatePromise({usernameFieldErrorText: 'Login.validation.username'})
      isError = true
    }

    this.setStatePromise({passwordFieldErrorText: ''})
    if(this.state.password === ''){
      this.setStatePromise({passwordFieldErrorText: 'Login.validation.password'})
      isError = true
    }

    if(!isError){
      doLoginPost({
        username: this.state.username,
        password: this.state.password
      })
        .then(
          loginHelper.responseSuccessLogin.bind(this),      
        )
        .catch(
        this.responseErrorLogin.bind(this))
    }
  }

  render () {
    let sepTextKey = 'home.login.or'
    let legendKey = 'home.login.legend'
    let renderSocialComponent = getBooleanConfig('socialLogin', true)
    if (!renderSocialComponent) {
      sepTextKey = 'home.login.legend'
      legendKey = undefined
    }
    return (
      <div className="homeView container">
        <HeaderTags title="headerTags.title"></HeaderTags>
        <div className="container row home">
          <div className="aviso-sego col-xs-12 py-2 row">
            <div className="col-md-1 text-center">
            <span class="material-symbols-outlined">
            info
            </span>
            </div>
            <div className="col-md-11">
            Te informamos que esta plataforma ya no está en funcionamiento. Podrás ver toda tu información iniciando sesión en la web  
             <a href="https://www.segofinance.com/login" target="_blank"><u> SEGOFINANCE </u></a> , dentro de la pestaña SEGO Funds (antes Fintup). ¡Disculpa las molestias!
            </div>
          </div>
          <div className="no-padding-izquierda leftContainer hidden-md hidden-xs col-xs-12 col-lg-6">
            <h1><FormattedMessage id="home.enter.title"></FormattedMessage></h1>
            <img src="/img/login.svg" alt="myExpertImg"/>
          </div>
          <div className="rightContainer container-fluid  col-lg-6">
            <div>
              <h2><FormattedMessage id="home.enter.login.title"></FormattedMessage></h2>
              <InputTextLabel placeholder="Login.validation.username" 
              name="username" theme="new"
              isError={this.state.usernameFieldErrorText != ''}
              errorTextKey={this.state.usernameFieldErrorText}
              callBackOnChange={this.commonHandleChange.bind(this)} />
              <InputTextLabel theme="new" type="password" 
              placeholder="Login.validation.password" name="password" 
              isError={this.state.passwordFieldErrorText != ''}
              errorTextKey={this.state.passwordFieldErrorText}
              callBackOnChange={this.commonHandleChange.bind(this)}/>
              <div className="loginButtonContainer">
                <FintupButton type="submit" 
                size={constants.BUTTON_SIZE.XLARGE} 
                textKey="home.login.button" 
                action={this.doLogin.bind(this)} />
              </div>
              <div className="forgetContainer" onClick={(ev) => this.showModalEv(ev, 'showModal')} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="home.login.forget" /></div>
              <Modal showModal={this.state.showModal}
               isOverlay={true}
               isFullPage={true} 
               clickOverlay={this.hideModals.bind(this)}
               modalHeader={this.modalGetHeader("Reactivate.titulo")}>
                <ModalBody>
                  <Reactivate isWidget={true}></Reactivate>
                </ModalBody>
              </Modal> 
            </div>
          </div>
        </div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,200" />
      </div>
     
    )
  }
}

export default injectIntl(HomeNew)
