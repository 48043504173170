import axios from 'axios'
import SessionController from '../core/SessionMobx'
import config from '../config/config'
import RSVP from 'rsvp'
import _ from 'lodash'
import transverser from '../utils/transverser'

export {
  changePasswordPublicService,
  changePasswordService,
  doChangeAlias,
  doGetCdnLiterals,
  doGetPhrases,
  doInvest,
  doLoginHelpdesk,
  doLoginPost,
  doLoginSocial,
  doSuitabilityTest,
  doSuitabilityContract,
  doLogoutPost,
  getGlobalPositionData,
  getGlobalPositionGraphic,
  getIndexes,
  getPerformances,
  getPortfolioData,
  getPortfolioMapData,
  getPortfoliosRecommendablesDataByProduct,
  getPortfoliosContratablesDataByProduct,
  getPortfolioRecommendableDataByProductAndRiskType,
  getListInsuranceCosts,
  getInsuranceCostByAge,
  getQuestionnaire,
  reactivateEmailService,
  doGetHotConfig,
  doGetHotConfigBackend,
  doGetDocumentTypes,
  doGetCountryList,
  doGetProvinceList,
  doGetMaritalTypeList,
  doGetInvestOriginList,
  doGetInvestTargetList,
  doUpdateContractForm,
  patchContractPeriodicData,
  getContractPeriodicData,
  getContractMainData,
  getProfitabilityReport,
  postQuestionnaire,
  postSimulation,
  postSimulationFriends,
  postMinitestReport,
  postValidateEmail,
  getValidateEmail,
  putContribution,
  putValidateEmail,
  getPortfolioPolicyData,
  doUploadDoc,
  doGetDocuments,
  getContribution,
  updateContributionDocument,
  unreadNotifications,
  countNotifications,
  existNotifications,
  readNotification,
  pendingContractPost,
  profileGet,
  existsDniId,
  unmaskProfileGet,
  adviserGet,
  profileUpdate,
  getSignByToken,
  getSignsUncompletes,
  profileUpdatePhoneDni,
  displayGet,
  doRegisterSocial,
  doRegister,
  doActivationPolicy,
  pensionPlanManagerGet,
  pensionPlanFundGet,
  pensionPlanPlanGet,
  pensionPlanTransferPut,
  academyGetCategories,
  academyGetOptionsCategory,
  doContractInvest,
  getContractInvestData,
  postActivationUser,
  pensionPlanContract,
  savingContract,
  operationIban,
  isUnitLinkProduct,
  confirmAcknowledgment,
  getInvitationLink,
  getInvitationMoney,
  getInvitationsNumber,
  postSendInvitations,
  getInvitations,
  getToolsProfitabilityProfiles,
  postCalculateOneMillion,
  getOneMillionValues,
  getProductData,
  getContractPersonalData,
  getContractMoneyLaundering,
  getContractFinancialData,
  contractInvestPost,
  getUserIncomes,
  doUpdateContractInvest,
  getExistContractMainData,
  contratableInvestProduct,
  getContractUserDocuments,
  getContributionsByContractId,
  registerAdviserQuestion,
  getGlobalPositionSummaryGraphic,
  getHistoryAdvice,
  adviceObjectivesList,
  adviceCategoriesList,
  historyAdviceSimulate,
  historyAdviceObjectiveSimulate,
  historyAdviceCreate,
  portfolioAdviceList,
  historyAdviceObjectiveCreate,
  historyAdviceObjectiveGet,
  historyAdviceObjectiveDelete
}

function generalRequest(axiosPromise, rsvpResolve, rspvReject) {
  axiosPromise.then(response => {
    let responseCode = transverser.get('data.result.code')(response) || transverser.get('data.code')(response) || transverser.get('data.resultDto.code')(response)
    if (response.status >= 200 && response.status < 300 && responseCode !== 'KO') {
      rsvpResolve(response)
    } else {
      rspvReject(response.data.result || response.data)
    }
  }).catch(error => {
    if (transverser.get('response.status')(error) === 401 && transverser.get('response.data.code', '')(error) === 'SESSIONEXPIRED') {
      SessionController.sessionExpired()
    } else {
      let message = transverser.get('response.data.message', 'Ha ocurrido un error inesperado')(error)
      if (message === 'null') {
        message = 'Ha ocurrido un error inesperado'
      }
      let result = {
        code: transverser.get('response.status', 500)(error),
        message: message
      }
      rspvReject(result)
    }
  })
}

function _isDeniedSecureRequest(isSecureRequest) {
  let isEmulated = SessionController.isEmulatedSession
  return isSecureRequest && isEmulated
}

function _getSecureRequestPromise() {
  let result = {
    code: 500,
    message: 'Sesion Emulada, operación no permitida'
  }
  return RSVP.reject(result)
}

function doPost(url, data, isSecureRequest = false, uploadRequest = false) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let axiosPromise = axios.post(url, data, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doPut(url, data, isSecureRequest = false, uploadRequest = false) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let axiosPromise = axios.put(url, data, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doPatch(url, data, isSecureRequest) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let axiosPromise = axios.patch(url, data, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doGet(url, isSecureRequest) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let urlNotCache = url.indexOf('?') === -1
        ? `${url}?${new Date().getTime()}`
        : `${url}&${new Date().getTime()}`
      let axiosPromise = axios.get(urlNotCache, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doDelete(url, isSecureRequest = false, data = {}) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let urlNotCache = url.indexOf('?') === -1
        ? `${url}?${new Date().getTime()}`
        : `${url}&${new Date().getTime()}`
      let deleteData = _.extend({}, getHeaders(), { data: data })
      let axiosPromise = axios.delete(urlNotCache, deleteData)
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function postSimulation(data) {
  const url = `${config.apiBaseUrl}/api/simulator`
  return doPost(url, data)
}

function postMinitestReport(data) {
  const url = `${config.apiBaseUrl}/api/minitest-report`
  return doPost(url, data)
}

function getProfitabilityReport(idClient) {
  const url = `${config.apiBaseUrl}/api/report/profitability-report/${idClient}`
  return doGet(url)
}

function postCalculateOneMillion(data) {
  const url = `${config.apiBaseUrl}/api/tools/pension-one-million`
  return doPost(url, data)
}

function postActivationUser(data) {
  console.log(window.location.hostname);
  let url = "";
  if(window.location.hostname.indexOf("preapp") > -1 || window.location.hostname.indexOf("localhost") > -1){
    console.log("desarrollo");
    url = `${config.apiSegoBaseDevUrl}/api/Usuarios/ActivarCuenta`
  }
  else{
    console.log("produccion");
    url = `${config.apiSegoBaseProdUrl}/api/Usuarios/ActivarCuenta`
  }
  return doPost(url, data)
}

function existsDniId(data) {
  const url = `${config.apiBaseUrl}/api/users/exists-dni-id/${data.dni}`
  return doGet(url)
}

function pendingContractPost(data) {
  const url = `${config.apiBaseUrl}/api/contracts/pending`
  return doPost(url, data)
}

function postSimulationFriends(data) {
  const url = `${config.apiBaseUrl}/api/simulator/friends`
  return doPost(url, data)
}

function changePasswordService(data) {
  const url = `${config.apiBaseUrl}/api/users/update-password`
  return doPost(url, data, true)
}

function changePasswordPublicService(data) {
  const url = `${config.apiBaseUrl}/api/users/public-update-password`
  return doPost(url, data)
}

function doLoginPost(data) {
  console.log(window.location.hostname);
  let url = "";
  if(window.location.hostname.indexOf("preapp") > -1 || window.location.hostname.indexOf("localhost") > -1){
    console.log("desarrollo");
    url = `${config.apiSegoBaseDevUrl}/auth/login`
  }
  else{
    console.log("produccion");
    url = `${config.apiSegoBaseProdUrl}/auth/login`
  }
  //const url = `${config.apiBaseUrl}/api/users/login`
  //return doPost(url, data)
}

function doLoginSocial(data) {
  const url = `${config.apiBaseUrl}/api/users/login/social`
  return doPost(url, data)
}

function doRegisterSocial(data) {
  const url = `${config.apiBaseUrl}/api/users/register-social-fintup`
  return doPost(url, data)
}

function doRegister(data) {
  console.log(window.location.hostname);
  let url = "";
  if(window.location.hostname.indexOf("preapp") > -1 || window.location.hostname.indexOf("localhost") > -1){
    console.log("desarrollo");
    url = `${config.apiSegoBaseDevUrl}/api/Usuarios/Registro`
  }
  else{
    console.log("produccion");
    url = `${config.apiSegoBaseProdUrl}/api/Usuarios/Registro`
  }
  return doPost(url, data)
}

function doActivationPolicy(data) {
  const url = `${config.apiBaseUrl}/api/activation-policy`
  return doPost(url, data)
}

function doLoginHelpdesk(data) {
  const url = `${config.apiBaseUrl}/api/users/loginHelpdesk`
  return doPost(url, data)
}

function doLogoutPost() {
  const url = `${config.apiBaseUrl}/api/users/logout`
  return doPost(url, {})
}

function reactivateEmailService(data) {
  const url = `${config.apiBaseUrl}/api/users/reactivate`
  return doPost(url, data)
}

function doInvest(data) {
  const url = `${config.apiBaseUrl}/api/customer`
  return doPost(url, data)
}

function doContractInvest(data) {
  const url = `${config.apiBaseUrl}/api/contracts/invest`
  return doPost(url, data)
}

function doUpdateContractInvest(data) {
  const url = `${config.apiBaseUrl}/api/contracts/update-invest`
  return doPatch(url, data)
}

function contractInvestPost(data = {}) {
  const url = `${config.apiBaseUrl}/api/contracts/contract-invest`
  return doPost(url, data)
}

function doSuitabilityTest(data) {
  const url = `${config.apiBaseUrl}/api/suitability/test`
  return doPost(url, data)
}

function doSuitabilityContract(data, externalId, step) {
  const url = `${config.apiBaseUrl}/api/customer/${externalId}/${step}`
  return doPost(url, data)
}

function postValidateEmail(data) {
  const url = `${config.apiBaseUrl}/api/verification-email`
  return doPost(url, data)
}

function putValidateEmail(data) {
  const url = `${config.apiBaseUrl}/api/verification-email/${data.validateEmailToken}`
  return doPut(url)
}

function getContractInvestData() {
  const url = `${config.apiBaseUrl}/api/customer/v1`
  return doGet(url)
}

function getUserIncomes() {
  const url = `${config.apiBaseUrl}/api/customer/user-incomes`
  return doGet(url)
}

function getExistContractMainData() {
  const url = `${config.apiBaseUrl}/api/contract-main-data/exist`
  return doGet(url)
}

function getValidateEmail(data) {
  const url = `${config.apiBaseUrl}/api/verification-email?email=${encodeURIComponent(data.email)}&token=${encodeURIComponent(data.validateEmailToken)}`
  return doGet(url, data)
}

function contratableInvestProduct() {
  const url = `${config.apiBaseUrl}/api/public-product/contratable-invest-product`
  return doGet(url)
}

function getSignByToken(data) {
  const url = `${config.apiBaseUrl}/api/public/sign/${data.token}`
  return doGet(url)
}

function getSignsUncompletes() {
  const url = `${config.apiBaseUrl}/api/sign/uncompletes/`
  return doGet(url)
}

function getProductData(data) {
  const url = `${config.apiBaseUrl}/api/product/${data.productId}`
  return doGet(url)
}

function isUnitLinkProduct(data) {
  const url = `${config.apiBaseUrl}/api/product/${data.productId}/is-unit-link`
  return doGet(url, data)
}

function academyGetCategories() {
  const url = `${config.apiBaseUrl}/api/academy/category/list`
  return doGet(url)
}

function academyGetOptionsCategory(data) {
  const url = `${config.apiBaseUrl}/api/academy/option/${data.categoryId}/list`
  return doGet(url)
}

function profileGet() {
  const url = `${config.apiBaseUrl}/api/profile`
  return doGet(url)
}

function unmaskProfileGet() {
  const url = `${config.apiBaseUrl}/api/profile/unmask`
  return doGet(url)
}

function adviserGet() {
  const url = `${config.apiBaseUrl}/api/adviser`
  return doGet(url)
}

function displayGet() {
  const url = `${config.apiBaseUrl}/api/display`
  return doGet(url)
}

function pensionPlanManagerGet() {
  const url = `${config.apiBaseUrl}/api/pension-plan/catalog`
  return doGet(url)
}

function pensionPlanFundGet(data) {
  const url = `${config.apiBaseUrl}/api/pension-plan/catalog/${data.manager}`
  return doGet(url)
}

function pensionPlanPlanGet(data) {
  const url = `${config.apiBaseUrl}/api/pension-plan/catalog/${data.manager}/${data.fund}`
  return doGet(url)
}

function pensionPlanContract(data) {
  const url = `${config.apiBaseUrl}/api/pension-plan/contract`
  return doPost(url, data)
}

function savingContract(data) {
  const url = `${config.apiBaseUrl}/api/saving/contract`
  return doPost(url, data)
}

function pensionPlanTransferPut(data) {
  const url = `${config.apiBaseUrl}/api/pension-plan/transfer`
  return doPut(url, data)
}

function profileUpdate(data) {
  const url = `${config.apiBaseUrl}/api/profile`
  return doPost(url, data)
}

function readNotification(data) {
  const url = `${config.apiBaseUrl}/api/notification/${data.notificationId}/read`
  return doPost(url, data)
}

function unreadNotifications() {
  const url = `${config.apiBaseUrl}/api/notification/unread`
  return doGet(url)
}

function existNotifications() {
  const url = `${config.apiBaseUrl}/api/notification/exists`
  return doGet(url)
}

function countNotifications() {
  const url = `${config.apiBaseUrl}/api/notification/count`
  return doGet(url)
}

function patchContractPeriodicData(data) {
  const url = `${config.apiBaseUrl}/api/contracts/periodicdata`
  return doPatch(url, data)
}

function getContractPeriodicData(data) {
  const url = `${config.apiBaseUrl}/api/contracts/periodicdata/${data.contractId}`
  return doGet(url)
}

function getContractMainData() {
  const url = `${config.apiBaseUrl}/api/contract-main-data`
  return doGet(url)
}

function getContractPersonalData() {
  const url = `${config.apiBaseUrl}/api/contract-main-data/personaldata`
  return doGet(url)
}

function getContractUserDocuments() {
  const url = `${config.apiBaseUrl}/api/contract-main-data/user-documents`
  return doGet(url)
}

function getContractMoneyLaundering() {
  const url = `${config.apiBaseUrl}/api/contract-main-data/moneylaundering`
  return doGet(url)
}

function getContractFinancialData() {
  const url = `${config.apiBaseUrl}/api/contract-main-data/financialdata`
  return doGet(url)
}

function doUpdateContractForm(data, step) {
  const url = `${config.apiBaseUrl}/api/contract-main-data/${step}`
  return doPost(url, data)
}

function getGlobalPositionData() {
  const url = `${config.apiBaseUrl}/api/global-position`
  return doGet(url)
}

function getPortfolioPolicyData(productId, policyNumber, valueDate, portfolioId) {
  const url = `${config.apiBaseUrl}/api/global-position/products/${productId}/policies/${policyNumber}?valueDate=${valueDate}&portfolioId=${portfolioId}`
  return doGet(url)
}

function getGlobalPositionGraphic(data) {
  const url = `${config.apiBaseUrl}/api/global-position/graphic/${data.productId}/${data.policyNumber}/${data.period}/${data.number}/${data.type}`
  return doGet(url)
}

function getGlobalPositionSummaryGraphic(data) {
  const url = `${config.apiBaseUrl}/api/consolidate-summary/graphic/${data.period}/${data.number}/${data.type}`
  return doGet(url)
}

function getPortfolioMapData(portfolioId) {
  const url = `${config.apiBaseUrl}/api/portfolio/${portfolioId}/geographical`
  return doGet(url)
}

function getPortfolioData(portfolioId) {
  const url = `${config.apiBaseUrl}/api/portfolio/${portfolioId}`
  return doGet(url)
}

//Contratables and recommendables
function getPortfoliosRecommendablesDataByProduct(productId) {
  const url = `${config.apiBaseUrl}/api/portfolio/${productId}/list-contratables-recommendables`
  return doGet(url)
}

//Only contratables
function getPortfoliosContratablesDataByProduct(productId) {
  const url = `${config.apiBaseUrl}/api/portfolio/${productId}/list-contratables`
  return doGet(url)
}

function getPortfolioRecommendableDataByProductAndRiskType(productId, riskTypeId) {
  const url = `${config.apiBaseUrl}/api/portfolio/${productId}/${riskTypeId}/contratable`
  return doGet(url)
}

function getListInsuranceCosts() {
  const url = `${config.apiBaseUrl}/api/insurance-cost`
  return doGet(url)
}

function getInsuranceCostByAge(age) {
  const url = `${config.apiBaseUrl}/api/insurance-cost/${age}`
  return doGet(url)
}

function getIndexes() {
  const url = `${config.apiBaseUrl}/api/indexes`
  return doGet(url)
}

function getPerformances(indexId) {
  const url = `${config.apiBaseUrl}/api/indexes/${indexId}/performances`
  return doGet(url)
}

function doGetPhrases() {
  const url = `${config.cdnBaseUrl}/json/marketingPhrases.json`
  return doGet(url)
}

function doGetHotConfig() {
  const url = `${config.cdnBaseUrl}${config.hotConfigUrl}`
  return doGet(url)
}

function doGetHotConfigBackend() {
  const url = `${config.apiBaseUrl}/api/config/list/FRONT`
  return doGet(url)
}

function doGetDocumentTypes() {
  const url = `${config.cdnBaseUrl}/json/documentTypes.json`
  return doGet(url)
}

function doGetCountryList() {
  const url = `${config.cdnBaseUrl}/json/countryList.json`
  return doGet(url)
}

function doGetProvinceList() {
  const url = `${config.cdnBaseUrl}/json/provincesList.json`
  return doGet(url)
}

function doGetMaritalTypeList() {
  const url = `${config.cdnBaseUrl}/json/maritalTypeList.json`
  return doGet(url)
}

function doGetInvestOriginList() {
  const url = `${config.cdnBaseUrl}/json/investOriginList.json`
  return doGet(url)
}

function doGetInvestTargetList() {
  const url = `${config.cdnBaseUrl}/json/investTargetList.json`
  return doGet(url)
}

function doGetCdnLiterals(language) {
  const url = `${config.cdnBaseUrl}/json/i18n/${config.cdnEnviroment}/${language}.json`
  return doGet(url)
}

function doChangeAlias(data) {
  const url = `${config.apiBaseUrl}/api/contracts`
  return doPatch(url, data)
}

function profileUpdatePhoneDni(data) {
  const url = `${config.apiBaseUrl}/api/profile/update-phone-dni`
  return doPatch(url, data)
}

function doUploadDoc(data) {
  const url = `${config.apiBaseUrl}/api/files/uploadFile`
  return doPost(url, data, false, true)
}

function doGetDocuments() {
  const url = `${config.apiBaseUrl}/api/files`
  return doGet(url)
}

function getQuestionnaire(data) {
  const url = `${config.apiBaseUrl}/api/questionnaire/v1/${data.questionnaireId}`
  return doGet(url)
}

function putContribution(data) {
  const url = `${config.apiBaseUrl}/api/contribution`
  return doPut(url, data)
}

function getContribution(data) {
  const url = `${config.apiBaseUrl}/api/contribution/${data.id}`
  return doGet(url)
}

function getContributionsByContractId(data) {
  const url = `${config.apiBaseUrl}/api/contribution/${data.contractId}/operation-list`
  return doGet(url)
}

function postQuestionnaire(data) {
  const url = `${config.apiBaseUrl}/api/questionnaire/v1`
  return doPost(url, data)
}

function operationIban(data) {
  const url = `${config.apiBaseUrl}/api/contribution/operation-iban`
  return doPost(url, data)
}

function updateContributionDocument(data) {
  const url = `${config.apiBaseUrl}/api/contribution/${data.id}/document`
  return doPost(url, data)
}

function confirmAcknowledgment(data) {
  const url = `${config.apiBaseUrl}/api/public/acknowledgment`
  return doPost(url, data)
}

function getInvitationLink() {
  const url = `${config.apiBaseUrl}/api/invitation/get-link`
  return doGet(url)
}

function getInvitationMoney() {
  const url = `${config.apiBaseUrl}/api/invitation/money`
  return doGet(url)
}

function getInvitationsNumber() {
  const url = `${config.apiBaseUrl}/api/invitation/exists`
  return doGet(url)
}

function getToolsProfitabilityProfiles() {
  const url = `${config.apiBaseUrl}/api/tools/expected-return-profiles`
  return doGet(url)
}

function getInvitations() {
  const url = `${config.apiBaseUrl}/api/invitation`
  return doGet(url)
}

function getOneMillionValues() {
  const url = `${config.apiBaseUrl}/api/tools/one-million-values`
  return doGet(url)
}

function postSendInvitations(data) {
  const url = `${config.apiBaseUrl}/api/invitation/send-invitations`
  return doPost(url, data)
}

function registerAdviserQuestion(data) {
  const url = `${config.apiBaseUrl}/api/user-adviser/register`
  return doPost(url, data)
}

function getHistoryAdvice() {
  const url = `${config.apiBaseUrl}/api/user-advice`
  return doGet(url)
}

function adviceObjectivesList() {
  const url = `${config.apiBaseUrl}/api/advice-objectives/list`
  return doGet(url)
}

function adviceCategoriesList() {
  const url = `${config.apiBaseUrl}/api/advice-categories/list`
  return doGet(url)
}

function historyAdviceObjectiveDelete(data) {
  const url = `${config.apiBaseUrl}/api/user-advice-objective/${data}`
  return doDelete(url)
}

function historyAdviceObjectiveCreate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice-objective`
  return doPost(url, data)
}

function historyAdviceObjectiveGet(id) {
  const url = `${config.apiBaseUrl}/api/user-advice-objective/${id}`
  return doGet(url)
}

function historyAdviceSimulate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice/simulate-user`
  return doPost(url, data)
}

function historyAdviceObjectiveSimulate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice-objective/simulate-user`
  return doPost(url, data)
}

function portfolioAdviceList() {
  const url = `${config.apiBaseUrl}/api/portfolio/list-advice`
  return doGet(url)
}

function historyAdviceCreate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice/create-user`
  return doPost(url, data)
}

function getHeaders(uploadRequest = false) {
  let token = SessionController.token
  let headers = {}
  if (!_.isEmpty(token)) {
    _.extend(headers, { token: token })
  }
  if (uploadRequest) {
    _.extend(headers, { 'Content-Type': 'multipart/form-data' })
  }
  return {
    headers: headers
  }
}
