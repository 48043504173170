import React from 'react'
import FintupComponent from "../FintupComponent";
import Terms from "../common/Terms";
import FintupButton from '../common/forms/FintupButton';
import constants from '../../utils/constants';
import './MiniTestRegister.scss';
import SocialLogin from '../common/SocialLogin';
import InputTextLabel from '../common/forms/InputTextLabel';
import CheckboxNew from '../common/forms/CheckboxNew';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import TermsModel from '../common/TermsModel';
import SessionController from '../../core/SessionMobx';
import utils from '../../utils/utils'
import _ from 'lodash'
import transverser, { tGet } from '../../utils/transverser'
import { injectIntl } from 'react-intl'
import { doRegister, doInvest } from '../../utils/fintup-api'
import FintupIcon from '../common/FintupIcon';
import RegisterCodeNew from '../register/RegisterCodeNew';
import AdviserModel from '../common/AdviserModel';
import config from '../../config/config';
import RSVP from 'rsvp'
import loginHelper from '../home/loginHelper';

/**
 * minitest: {true|false}
 * showBreadcrumbs: {true|false}
 */
class MiniTestRegister extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      user: {},
      showButtons: true,
      showFields: false,
      showActivationCode: false,
      registerButtonActive: false,
      invitationCode: undefined,
      isPrivateSession: SessionController.isLogged(),
      values: props.model,
      //errors
      nameFieldErrorText: '',
      surnameFieldErrorText: '',
      emailFieldErrorText: '',
      passwordFieldErrorText: '',
      passwordConfirmFieldErrorText: '',
      buttonGoogleDisabled: true
    }
  }

  handleChange (data) {
    let fieldName = data.ev.target.name
    let fieldValue = data.ev.target.value
    this.setStatePromise({ [fieldName]: fieldValue }).then(data => {
      console.log("THIS STATE: ", this.state)
    })
  }

  showButtons(){
    this.setStatePromise({showButtons: true, showFields: false})
  }

  showFields(){
    this.setStatePromise({showButtons: false, showFields: true})
  }

  //Form by email
  register(){
    let isError = false;

    this.setStatePromise({nameFieldErrorText: ''})
    if (utils.isEmpty(this.state.user.name)) {
      this.setStatePromise({nameFieldErrorText: 'regsterView.validation.name'})
      isError = true
    }
    
    this.setStatePromise({surnameFieldErrorText: ''})
    if (utils.isEmpty(this.state.user.surname)) {
      this.setStatePromise({surnameFieldErrorText: 'regsterView.validation.surname'})
      isError = true
    }
    
    this.setStatePromise({emailFieldErrorText: ''})
    if (!utils.validateEmail(this.state.user.email)) {
      this.setStatePromise({emailFieldErrorText: 'regsterView.validation.email'})
      isError = true
    }
    
    let password = _.trim(tGet('user.password')(this.state))
    let passwordConfirm = _.trim(tGet('user.passwordConfirm')(this.state))

    this.setStatePromise({passwordFieldErrorText: ''})
    if (password === '') {
      this.setStatePromise({passwordFieldErrorText: 'ChangePassword.fillPassword'})
      isError = true
    }

    this.setStatePromise({passwordFieldErrorText: ''})
    if (!utils.validatePassword(password)) {
      this.setStatePromise({passwordFieldErrorText: 'ChangePassword.validatePassword'})
      isError = true
    }
    
    this.setStatePromise({passwordConfirmFieldErrorText: ''})
    if (password !== passwordConfirm) {
      this.setStatePromise({passwordConfirmFieldErrorText: 'ChangePassword.samePassword'})
      isError = true
    }
    //First fields, after validation checkbox
    if(isError)
      return;
    
    //Check validation conditions
    isError = this.validateConditions()
    
    if(isError)
      return;

    //First invest, after 
    this.invest().then(() => {
      if (this.state.isPrivateSession) {
        return RSVP.resolve()
      } else {
        let registerData = _.merge(
          {
            adviserId: AdviserModel.adviser.id,
            origin: constants.REGISTER.ORIGIN.MINITEST,
            invitation: this.state.invitationCode, //undefined
            communicationTerms: true,
            profilesTerms: true
          },
          this.state.user)
        console.log("USER REGISTER: ", registerData)
        registerData.origin = "FINTUPMINITEST";
        return doRegister(registerData)
      }
    }).then(() => { //Change view to registerCode component
      return this.setStatePromise({
        showFields: false, 
        showActivationCode: true})
        .then(() => {
          this.handleNotification({titleKey: 'regsterView.validation.oktitle', textKey: 'regsterView.validation.ok', type: constants.NOTIFICATION_TYPE.SUCCESS})
        })
      //navUtils.goToExternal(urls.internal.activationCode)
    }).catch((result) => {
      console.log("error invest register normal: ", result)
      this.handleNotification({titleKey: 'regsterView.error', textKey: result.message, type: constants.NOTIFICATION_TYPE.ERROR})
    })
  }

  //Form by google
  registerSocialMinitest(socialDataResponse){
    console.log("register social register new", socialDataResponse)
    return this.invest(true, socialDataResponse)
  }

  /**
   * Potential customer
   * @param {*} isSocial 
   * @param {*} socialData data response ok google register
   */
  invest(isSocial = false, socialData = {}){
    console.log("IS SOCIAL: ", isSocial)
    console.log("SOCIAL DATA: ", socialData)
    console.log("DATA MINITEST: ", this.state.values)

    let miniTestQuestions = {
      objective: transverser.get('objective')(this.state.values),
      objectiveText: transverser.get('objectiveText')(this.state.values),
      years: transverser.get('years')(this.state.values),
      contributionInitial: transverser.get('contributionInitial')(this.state.values),
      contributionMonthly: transverser.get('contributionMonthly')(this.state.values),
      typeInvestor: transverser.get('typeInvestor')(this.state.values),
      typeInvestorIcon: transverser.get('typeInvestorIcon')(this.state.values),
      typeInvestorText: transverser.get('typeInvestorText')(this.state.values)
    }
    console.log("QUESTIONS MINITEST: ", miniTestQuestions)

    //Similar to CustomerPotentialDTO
    //If isSocial=true -> not exists email, name and surname, complete it as ""
    let miniTestRequest = { 
      email: isSocial ? "" : this.state.user.email,
      responsesData: {
        step1: {
          name: isSocial ? "" : this.state.user.name+" "+this.state.user.surname, 
          age: 18
        }, 
        step2: {
          reason: miniTestQuestions.objective,
          time: miniTestQuestions.years,
          amount: miniTestQuestions.contributionInitial, //initial amount
          amountMonthly: miniTestQuestions.contributionMonthly //monthly amount
        }, 
        step3: {
          incomes: 0,
          stable: miniTestQuestions.typeInvestor,
          expensesPercent: miniTestQuestions.typeInvestor
        }, 
        step4: {
          investPreviously: miniTestQuestions.typeInvestor,
          activeAmount: 0
        }, 
        step5: {
          validateEmailToken: null,
          lostInvest: miniTestQuestions.typeInvestor,
          profitabilityTarget: miniTestQuestions.typeInvestor,
          email: isSocial ? "" : this.state.user.email,
          typeInvestor: miniTestQuestions.typeInvestor
        }
      },
      suitabilityTestKey: config.suitabilityTestKey,
      sourceSocial: isSocial,
      tokenSocial: isSocial ? socialData.idToken : null,
      adviserId: AdviserModel.adviser.id,
    }

    console.log("Minitest request: ", miniTestRequest)

    //Potential customer
    return doInvest(miniTestRequest).then(response => {
      let externalId = {
        externalId: response.data.externalId
      }
      console.log("EXTERNAL ID (response potential client): ", externalId)
      //return this.props.updateModel({ externalId: externalId })
    })
  }
  
  validateConditions(){
    let errorValidate = this.errorValidateConditions()
    if(errorValidate){
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'regsterView.validation.dataProcessingTerms', type: constants.NOTIFICATION_TYPE.ERROR})
    }
    return errorValidate;
  }

  errorValidateConditions(){
    
    let isError = false
    //Checkbox conditions
    //console.log("CONDITIONS: ", this.state.user)
    if (!this.state.user.dataProcessingTerms) {
      isError = true
    }
    
    return isError
  }

  validateConditionChange(data){
    return this.commonCheckboxChange(data).then((response) => {
      //console.log("DATA CHECKBOX valdiateConditionsChange", response)
      let disabledConditions = this.errorValidateConditions()
      //console.log("DISABLE GOOGLE BUTTON: ", disabledConditions)
      return this.setStatePromise({buttonGoogleDisabled: disabledConditions})
    })
  }
  
  //Response ok google
  responseOkLogin(){
    this.handleNotification({titleKey: 'regsterView.validation.oktitle', textKey: 'regsterView.validation.ok', type: constants.NOTIFICATION_TYPE.SUCCESS})
    return RSVP.resolve()
  }

  //Response error google
  responseErrorLogin (response) {
    this.handleNotification({titleKey: 'regsterView.error', textKey: response.message ? response.message : response.textKey, type: constants.NOTIFICATION_TYPE.ERROR})
    return RSVP.reject()
  }

  get renderStepsBlock(){
    return this.props.showBreadcrumbs ? 
    (
      <div className="headerStepsBlock">
        <div className="container">
          <div className="row breadcrumbBlock">
            <div className="col-xs-4 bread previous" onClick={() => this.props.prevStep()}>
              <div className="crumb">
                <FormattedMessage id="breadcrumb.previous"/>
                <span className="icon-wrapper"><i className="fa fa-check"></i></span>
              </div>
            </div>
            <div className="col-xs-4 bread active">
              <div className="crumb">
                <FormattedMessage id="breadcrumb.active"/>
                <FintupIcon size="10" icon="flecha-seleccionable-abajo"/>
              </div>
            </div>
            <div className="col-xs-4 bread next">
              <div className="crumb">
                <FormattedMessage id="breadcrumb.next"/>
                <FintupIcon size="12" icon="flecha-seleccionable-derecha"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 previous" onClick={() => this.props.prevStep()}>
              <img src="/img/recommendation.svg"/>
              <h4><FormattedMessage id="regsterView.landing.1.title"/></h4>
              <p><FormattedMessage id="regsterView.landing.1.text"/></p>
            </div>
            <div className="col-md-4">
              <img src="/img/revision.svg"/>
              <h4><FormattedMessage id="regsterView.landing.2.title"/></h4>
              <p><FormattedMessage id="regsterView.landing.2.text"/></p>
            </div>
            <div className="col-md-4">
              <img src="/img/my-expert.svg"/>
              <h4><FormattedMessage id="regsterView.landing.3.title"/></h4>
              <p><FormattedMessage id="regsterView.landing.3.text"/></p>
            </div>
          </div>
        </div>
      </div>
    ) 
    : ''
  }

  get renderConditionsTerms(){
    let acceptText = <FormattedHTMLMessage id="registerView.acceptText" />
    let dataProcessingTermsSubText = <a href={TermsModel.urls.dataProcessingTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.dataProcessingTerms.acceptLink" /></a>
    let dataProcessingTermsText = <FormattedMessage id="regsterView.dataProcessingTerms.new" values={{ tag1: acceptText, tag2: dataProcessingTermsSubText }} />
    let communicationTermsSubText = <a href={TermsModel.urls.communicationTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.communicationTerms.acceptLink" /></a>
    let communicationTermsText = <FormattedMessage id="regsterView.communicationTerms.new" values={{ tag1: acceptText, tag2: communicationTermsSubText }} />
    let profilesTermsSubText = <a href={TermsModel.urls.profilesTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.profilesTerms.acceptLink" /></a>
    let profilesTermsText = <FormattedMessage id="regsterView.profilesTerms.new" values={{ tag1: acceptText, tag2: profilesTermsSubText }} />

    return (
      <div className="container terms">
        <CheckboxNew theme="new" name="user.dataProcessingTerms" className="checkboxTerm"
        text={dataProcessingTermsText} value={this.state.user.dataProcessingTerms} 
        onChangeCheckbox={this.validateConditionChange.bind(this)} />
        {/*<CheckboxNew theme="new" name="user.communicationTerms" className="checkboxTerm"
        text={communicationTermsText} value={this.state.user.communicationTerms} 
        onChangeCheckbox={this.validateConditionChange.bind(this)} />
        <CheckboxNew theme="new" name="user.profilesTerms" className="checkboxTerm"
        text={profilesTermsText} value={this.state.user.profilesTerms} 
        onChangeCheckbox={this.validateConditionChange.bind(this)} />*/}
      </div>
    )
  }

  render(){
    return (
      <div className="miniTestRegister">
        <div className="contentFull text-center">
          {this.renderStepsBlock}          
          <div className="registerBlock">
            <div className="title">
              <h3>
                {this.state.showActivationCode ? 
                 <FormattedMessage id="regsterView.titlecode"/>
                 : 
                 <FormattedMessage id="regsterView.titletest"/>
                }
              </h3>
            </div>
            <div className="formBlock">
              {
                this.state.showButtons ? 
                (
                  <div>
                    <div className="buttons">
                      <SocialLogin 
                      invitationCode={this.state.invitationCode}
                      register={true}
                      registerMinitest={true}
                      registerMinitestAction={this.registerSocialMinitest.bind(this)}
                      showAlert={this.responseErrorLogin.bind(this)}
                      successLogin={loginHelper.responseSuccessLogin.bind(this)}
                      errorLogin={this.responseErrorLogin.bind(this)}
                      disabled={this.state.buttonGoogleDisabled}
                      onValidate={this.validateConditions.bind(this)}
                      additionalRquestData={{
                        dataProcessingTerms: this.state.user.dataProcessingTerms,
                        communicationTerms: true,
                        profilesTerms: true
                      }}/>
                      <FintupButton 
                        iconImage="/img/email-register.svg" 
                        textKey="regsterView.email"
                        className="buttonEmail"
                        size={constants.BUTTON_SIZE.FREE}
                        action={this.showFields.bind(this)} />
                    </div>
                    {this.renderConditionsTerms}
                  </div>
                )
                : ''
              }
              {
                this.state.showFields ? 
                (
                  <div className="fieldsBlock">
                    <div className="container fields">
                      <div className="row">
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="text"
                            placeholder="Nombre"
                            name="user.name" 
                            theme="new"
                            isError={this.state.nameFieldErrorText != ''}
                            errorTextKey={this.state.nameFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="text"
                            placeholder="Apellidos"
                            name="user.surname" 
                            theme="new"
                            isError={this.state.surnameFieldErrorText != ''}
                            errorTextKey={this.state.surnameFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="text"
                            placeholder="Email"
                            name="user.email" 
                            theme="new"
                            isError={this.state.emailFieldErrorText != ''}
                            errorTextKey={this.state.emailFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                            <InputTextLabel 
                            type="password"
                            placeholder="Contraseña"
                            name="user.password" 
                            theme="new"
                            isError={this.state.passwordFieldErrorText != ''}
                            errorTextKey={this.state.passwordFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="password"
                            placeholder="Confirma tu contraseña"
                            name="user.passwordConfirm" 
                            theme="new"
                            isError={this.state.passwordConfirmFieldErrorText != ''}
                            errorTextKey={this.state.passwordConfirmFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4 buttonsFields">
                          <FintupButton 
                          fintupIcon="flecha-seleccionable-izquierda" 
                          iconRight={true} 
                          textKey="button.common.next" 
                          className="buttonPrev" buttonClassName="btn-bold" 
                          size={constants.BUTTON_SIZE.ONLY_ICON} 
                          action={this.showButtons.bind(this)} />
                          <FintupButton 
                            type="submit"
                            disabled={this.state.registerButtonActive}
                            fintupIcon="flecha-seleccionable-derecha" 
                            iconRight={true}
                            className="buttonAttention"
                            textKey="Registrarte" 
                            size={constants.BUTTON_SIZE.XXLARGE} 
                            action={this.register.bind(this)}/>
                        </div>
                      </div>
                    </div>
                    {this.renderConditionsTerms}
                  </div>
                ) : ''
              }
              {
                this.state.showActivationCode ? 
                (
                  <div className="fieldsBlock">
                    <RegisterCodeNew 
                     classNameContainer="fields"
                     user={this.state.user}></RegisterCodeNew>
                  </div>
                ) : ''
              }
            </div>
          </div>
          <Terms/>
        </div>
      </div>
    )
  }
}

export default injectIntl(MiniTestRegister)