let configCommon = {
  hotConfigUrl: '/json/externalConfig.json',
  needLogin: true,
  apiBaseUrl: 'https://api.fintup.com',
  cdnBaseUrl: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com',
  apiSegoBaseDevUrl: 'https://test-api-segofinance.azure-api.net/api/Fintup',
  apiSegoBaseProdUrl: 'https://api-segofinance.azure-api.net/api/Fintup',
  segoBaseDevUrl: 'https://dev.segofinance.com',
  segoBaseProdUrl: 'https://www.segofinance.com',
  cdnEnviroment: 'pro',
  mobileMinWidth: 767,
  captchaKey: '6LdrykIUAAAAAC3tAQ43Z72kQfTyj5pXv-bmxuWJ',
  mapKey: 'AIzaSyA6ejegk0qAT-eIF0-CGmzeiHSiO4ous9o',
  // mapKey: 'AIzaSyC-6Uov-vP1wJcMafFk7QloTgHS_PrbkGQ',
  captchaTimeout: 5000,
  captchaEnable: true,
  suitabilityTestKey: 'f89g0eajlrvihln7qlntfqs6k7',
  showMap: true,
  googleAnalytics: 'UA-115030746-1',
  googleAnalyticsEnabled: true,
  calendlyEnable: true,
  socialLogin: true,
  googleLoginApiKey: '186120502170-bviidljb6md38k3eo7rnig84ho0313be.apps.googleusercontent.com',
  urlFundKid: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/kids/[fundIsin].pdf?date=20191122',
  enableEditLabel: true,
  enableDocumentView: false,
  suitabilityValidateEmail: false,
  checkEmailInterval: 10000,
  showSimulatorSliders: false,
  enableTagManager: true,
  googleTagManager: 'GTM-WZ8GW7D',
  swipePrecission: 100,
  pensionPlanTransfer: false,
  enableRegister: false,
  newTools: false,
  newAcademy: false
}

export default configCommon
