import SessionController from '../../core/SessionMobx'
import RSVP from 'rsvp'
import config from '../../config/config'
import moment from 'moment'

const responseSuccessLogin = function (response) {
  /*let sessionMinutes = parseInt(config.sessionMaxTimeMinutes, 10)

  let limitSessionDate = moment().add(sessionMinutes, 'minutes')

  SessionController.setSession({
    user: response.data.user.username,
    name: response.data.user.name,
    token: response.data.token,
    userData: response.data.user,
    oneTimePassword: response.data.oneTimePassword,
    emulateSession: false,
    partnerSession: false,
    redirectUrl: response.data.redirectUrl,
    extendData: {
      limitSessionDate: limitSessionDate
    }
  })
  this.setState()*/
  let url = "";
  if(window.location.hostname.indexOf("preapp") > -1 || window.location.hostname.indexOf("localhost") > -1){
    console.log("desarrollo");
    url = `${config.segoBaseDevUrl}`
  }
  else{
    console.log("produccion");
    url = `${config.segoBaseProdUrl}`
  }
  window.location.href = url + "?accesstoken=" + response.data.accesstoken
  //SessionController.redirectToUnloggedUrl()
  return RSVP.resolve()
}

const loginHelper = {
  responseSuccessLogin: responseSuccessLogin
}

export default loginHelper
